
import { defineComponent, ref, onMounted, nextTick } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { PasswordMeterComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
export default defineComponent({
  name: "change-password",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const phone = ref(null);
    const formData = ref({
      email: "",
    });

    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const profile = ref<any>(false);
    const submitButton = ref<HTMLButtonElement | null>(null);
    const loading = ref<boolean>(false);
    const page = ref<string>("");

    // const value = ref('');
    const registration = Yup.object().shape({
      email: Yup.string().email().required().label("Email"),
    });

    const onSubmitRegister = (values, { resetForm }) => {
      // Clear existing error
      // eslint-disable-next-line
        submitButton.value!.disabled = true;

      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      console.log("values", values);
      // console.log('resetForm',resetForm);
      //   var request = {
      //     url: 'change-password',
      //     data: {
      //       old_password: values.currentpassword,
      //       new_password: values.newpassword,
      //       user_id:
      //     },
      //   };
      // Dummy delay
      //   setTimeout(() => {password
      // Send login request
      loading.value = true;
      var request = {
        url: "forgot-password",
        data: {
          email: values.email,
        },
      };
      store
        .dispatch(Actions.FORGOT_PASSWORD, request)
        .then((data) => {
          if (data) {
            resetForm();
            Swal.fire(
              "Success",
              "Password reset email have been successfully sent!",
              "success"
            );
          }
          loading.value = false;
        })
        .catch(() => {
          const [error] = Object.keys(store.getters.getErrors);
          Swal.fire({
            text: store.getters.getErrors[error],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try again!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
      // store
      //   .dispatch(Actions.POST, request)
      //   .then((data) => {
      //     if(data){
      //       resetForm();
      //       Swal.fire({
      //         text: "You have successfully Changed your Password!",
      //         icon: "success",
      //         buttonsStyling: false,
      //         confirmButtonText: "Ok, got it!",
      //         customClass: {
      //           confirmButton: "btn fw-bold btn-light-primary",
      //         },
      //       }).then(function () {
      //         router.push({ name: "dashboard" });

      //       });
      //     }
      //   })
      //   .catch(() => {
      //     const [error] = Object.keys(store.getters.getErrors);
      //     Swal.fire({
      //       text: store.getters.getErrors[error],
      //       icon: "error",
      //       buttonsStyling: false,
      //       confirmButtonText: "Try again!",
      //       customClass: {
      //         confirmButton: "btn fw-bold btn-light-danger",
      //       },
      //     });
      //   });

      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
        submitButton.value!.disabled = false;
      //   }, 2000);
      // Swal.fire({
      //   text: "User added successfully!",
      //   icon: "success",
      //   buttonsStyling: false,
      //   confirmButtonText: "Ok, got it!",
      //   customClass: {
      //     confirmButton: "btn fw-bold btn-light-primary",
      //   },
      // }).then(function () {
      //   router.push({ name: "admin-user" });
      // });
    };

    return {
      registration,
      onSubmitRegister,
      submitButton,
      loading,
      phone,
      formData,
      page,
    };
  },
});
